<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25 text-primary">
      © {{ new Date().getFullYear() }}
      <b-link class="ml-25" href="http://www.unuvar.com/" target="_blank"
        >Ünüvar Elektronik San. ve Tic. A. Ş.</b-link
      >
    </span>

    <span class="float-md-right d-none d-md-block">
      <b-link class="ml-25" href="https://www.shaesk.com/" target="_blank"
        >SHAESK</b-link
      >
      <feather-icon
        icon="AirplayIcon"
        size="20"
        class="text-primary stroke-current"
      />
    </span>
  </p>
</template>

<script>
import { BLink } from "bootstrap-vue";

export default {
  components: {
    BLink
  }
};
</script>
